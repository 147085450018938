<template>
  <div>
    <NavBar :leng="datos.leng" />
    <div class="container px-0 px-sm-3 px-md-4 px-lg-4 px-2 mt-xxl-4">
      <div :style="`height: ${datos.espaciador}`"></div>
      <div class="row justify-content-center">
        <div class="col-md-4 col-12">
          <div class="w-100">
            <h3 class="text-white text-center futurism" style="--bs-text-opacity: 0.5">Mint</h3>
          </div>
        </div>
      </div>
      <div :style="`height: ${datos.espaciador}`"></div>
      <div class="w-100 px-5">
        <div class="row">
          <div class="col-sm-6 col-md-4 col-12 px-sm-2 px-md-3 px-lg-5 px-1 pb-sm-2 pb-md-1 pb-5">
            <div class="w-100 px-sm-2 px-md-3 px-lg-5 px-1">
              <div
                class="w-100 position-relative"
                @click="combineModal('hour')"
                @keyup="combineModal('hour')"
              >
                <div class="w-100 position-absolute">
                  <img
                    src="../assets/images/corchetes.svg"
                    alt="."
                    id="corchetes-mint3"
                    style="min-width: 100%; height: auto"
                  />
                </div>
                <div class="w-100 p-sm-2 p-md-3 p-2">
                  <video
                    id="mint-3"
                    muted
                    preload="metadata"
                    loop
                    poster="../assets/videos/mint-grande-3.png"
                  >
                    <source src="../assets/videos/mint-grande-3.mp4" type="video/mp4" />
                    Su navegador no soporta contenido multimedia.
                    <track src="" kind="captions" srclang="es" label="" />
                  </video>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-12 px-sm-2 px-md-3 px-lg-5 px-1 pb-sm-2 pb-md-1 pb-5">
            <div class="w-100 px-sm-2 px-md-3 px-lg-5 px-1">
              <div
                class="w-100 position-relative"
                @click="combineModal('minute')"
                @keyup="combineModal('minute')"
              >
                <div class="w-100 position-absolute">
                  <img
                    src="../assets/images/corchetes.svg"
                    alt="."
                    id="corchetes-mint2"
                    style="min-width: 100%; height: auto"
                  />
                </div>
                <div class="w-100 p-sm-2 p-md-3 p-2">
                  <video
                    id="mint-2"
                    muted
                    preload="metadata"
                    loop
                    poster="../assets/videos/mint-grande-2.png"
                  >
                    <source src="../assets/videos/mint-grande-2.mp4" type="video/mp4" />
                    Su navegador no soporta contenido multimedia.
                    <track src="" kind="captions" srclang="es" label="" />
                  </video>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-12 px-sm-2 px-md-3 px-lg-5 px-1 pb-sm-2 pb-md-1 pb-5">
            <div class="w-100 px-sm-2 px-md-3 px-lg-5 px-1">
              <div class="w-100 position-relative" @click="ConfirmMint()" @keyup="ConfirmMint()">
                <div class="w-100 position-absolute">
                  <img
                    src="../assets/images/corchetes.svg"
                    alt="."
                    id="corchetes-mint1"
                    style="min-width: 100%; height: auto"
                  />
                </div>
                <div class="w-100 p-sm-2 p-md-3 p-2">
                  <video
                    id="mint-1"
                    muted
                    preload="metadata"
                    loop
                    poster="../assets/videos/mint-grande-1.png"
                  >
                    <source src="../assets/videos/mint-grande-1.mp4" type="video/mp4" />
                    Su navegador no soporta contenido multimedia.
                    <track src="" kind="captions" srclang="es" label="" />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :style="`height: ${datos.espaciador}`"></div>
      <div class="row justify-content-center">
        <div class="col-md-4 col-12">
          <div role="button" tabindex="0" @click="textTeam()" @keyup="textTeam()" class="w-100">
            <p
              class="text-white text-center futurism"
              style="--bs-text-opacity: 0.5; font-size: 0.7rem"
            >
              ABOUT
            </p>
          </div>
        </div>
      </div>
      <div :style="`height: ${datos.espaciador}`"></div>
      <div class="row justify-content-center">
        <div class="col-auto">
          <div class="w-100">
            <ul class="nav justify-content-center">
              <li class="nav-item ps-0">
                <a class="nav-link" href="https://www.marabiertonft.xyz">
                  <p class="text-white text-opacity-50 futurism">PAST</p>
                </a>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ path: '#' }">
                  <p class="text-white futurism" :style="`${datos.present}`">PRESENT</p>
                </router-link>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="http://tempoland.xyz">
                  <p class="text-white futurism" id="future" :style="`${datos.future}`">FUTURE</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div :style="`height: ${datos.espaciador}`"></div>
    </div>
    <modal-container />
    <Footer2Section />
  </div>
</template>

<script setup>
/* eslint-disable */
import NavBar from "@/components/NavBar.vue";
import Footer2Section from "@/components/Footer2Section.vue";
import { onBeforeMount, onMounted, reactive, ref } from "vue";
import { useRoute } from "vue-router";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";
import { SplitText } from "gsap/SplitText";
import ModalConfirm from "@/components/modals/ModalConfirm.vue";
// import ModalCommingsoon from "@/components/modals/ModalCommingsoon.vue";
import ModalCombine from "@/components/modals/ModalCombine.vue";
import { container as ModalContainer, openModal, popModal, config } from "jenesius-vue-modal";

import { init, mintToken, setNft } from "../utilities/web3";
import { useStore } from "vuex";

const store = useStore();
const url = ref(
  "https://testnets.opensea.io/assets/goerli/0x1452dfDa94f7a996De5bd95AfCE861142BeC3749/"
);
const tokenId = ref('');

const ConfirmMint = () => {
  if (confirm("Esta seguro de querer mintear, Cada token tiene un costod de 0.1 Eth")) {
    MintToken();
  }
};

const MintToken = async () => {
  await init().catch(() => alert("necesitas estar conectado para poder Mintear"));
  const contract = store.state.contract;
  //await setNft();
  try {
    await mintToken(1, contract).then((data) => {
      tokenId.value = (Number(data) - 1);
      if (
        confirm("Token minteado con exito, si acepta se abrira una nueva pestaña donde podra verlo")
      ) {
        open(url.value + tokenId.value);
      }
    });
  } catch (error) {
    console.log(error);
  }
};

gsap.registerPlugin(ScrollTrigger, TextPlugin, SplitText);
const route = useRoute();

const datos = reactive({
  leng: "",
  segundos: "--bs-text-opacity: .12;",
  minutos: "--bs-text-opacity: .12;",
  horas: "--bs-text-opacity: .12;",
  present: "--bs-text-opacity: .75;",
  future: "--bs-text-opacity: .5;",
  tlCorchetes1: null,
  tlCorchetes2: null,
  tlCorchetes3: null,
  espaciador: 0,
  timer: null,
  commingsoon: true,
});

const leng = async () => {
  if (route.params.leng === "zh") {
    datos.leng = "zh";
  }
};

const textTeam = async () => {
  let label = "";
  let textI = "";
  let textII = "";
  let textIII = "";
  let textIV = "";

  label = "THIS IS NOT A WATCH";
  textI = `
    A new temporal ecosystem begins. We, creatures made of time, are
    organizing to be masters of our future. Non fungible time is a
    collection of digital files representing moments, happenings,
    occurrences, physics or a wide variety of events
  `;
  textII = `
    The usual measurement of time in hours, minutes and seconds turns
    into a sequence of animated tri-dimensional objects that are
    created and combined when their owners decide so. The number
    representing seconds will eventually become the collapse of a
    glacier, gravity, a birth, the ripening of a fruit, the sound of rain on a
    roof or a wormhole; scenes for our large project of the world of time.
  `;
  textIII = `
    Moving forwards always requires leaving something behind. We blockchain
    time which is non-fungible and at the same time we burn objects to build it.
    Time goes by and not always forwards. It is not linear nor does it
    advance along a single trajectory. Events follow one after another
    and we keep travelling with them from the past to the future and back to the past.
  `;
  textIV = `
    In Non Fungible Time, we will decide together what to do with time.
    How much time do you have or need? How much time do you want to dedicate to the future?
  `;

  function resolveConfirm() {
    popModal();
  }
  openModal(ModalConfirm, {
    label,
    textI,
    textII,
    textIII,
    textIV,
    resolve: resolveConfirm,
  });
};

const commingSoon = async () => {
  if (datos.commingsoon) {
    datos.commingsoon = false;
    config({
      backgroundClose: false,
    });
    const linck = "http://tempoland.xyz";
    const resolveConfirm = () => {
      popModal();
    };
    /* await openModal(ModalCommingsoon, {
      linck,
      resolve: resolveConfirm(),
    }); */
  }
};

const combineModal = async (type) => {
  config({
    backgroundClose: false,
  });
  const resolveConfirm = () => {
    popModal();
  };
  await openModal(ModalCombine, {
    type,
    resolve: resolveConfirm(),
  });
};

const aniOpacity = async (obj, s) => {
  const tl = gsap.timeline({ repeat: -1 });
  tl.fromTo(obj, { opacity: 0.5, ease: "none" }, { opacity: 1, duration: s });
  tl.fromTo(obj, { opacity: 1, ease: "none" }, { opacity: 0.5, duration: s });
  if (obj === "#corchetes-mint1") {
    datos.tlCorchetes1 = tl;
  }
  if (obj === "#corchetes-mint2") {
    datos.tlCorchetes2 = tl;
  }
  if (obj === "#corchetes-mint3") {
    datos.tlCorchetes3 = tl;
  }
};

/* const aniOpacityPause = async (obj) => {
  if (obj === '#segundos-text') {
    datos.tlSegundos.pause();
  }
  if (obj === '#minutos-text') {
    datos.tlMinutos.pause();
  }
  if (obj === '#horas-text') {
    datos.tlHoras.pause();
  }
}; */

/* const detener = async (num) => {
  const pause = document.getElementById(`${num}-demo`);
  if (pause) {
    await pause.pause();
    await pause.load();
  }
}; */

const reproducir = async (num) => {
  const play = document.getElementById(`${num}`);
  if (play) {
    if (num === "") {
      setTimeout(async () => {
        await play.play();
      }, 5000);
    } else {
      await play.play();
      setTimeout(async () => {
        commingSoon();
      }, 7000);
    }
  }
};

const tScreen = async () => {
  datos.espaciador = "5vh";
  const height = document.documentElement.scrollHeight;
  if (height >= 668 && height < 767.99) {
    datos.espaciador = "5vh";
  } else if (height >= 768 && height < 899.99) {
    datos.espaciador = "7vh";
  } else if (height >= 900 && height < 1023.99) {
    datos.espaciador = "9vh";
  } else if (height >= 1024 && height < 1151.99) {
    datos.espaciador = "10vh";
  } else if (height >= 1152 && height < 1200) {
    datos.espaciador = "11vh";
  } else if (height >= 1210 && height < 1300) {
    datos.espaciador = "12vh";
  } else if (height >= 1310) {
    datos.espaciador = "13vh";
  }
};

const playScreen = async () => {
  if (datos.timer === null) {
    tScreen();
    datos.timer = setInterval(() => tScreen(), 1000);
  } else {
    clearInterval(datos.timer);
    datos.timer = null;
  }
};

onBeforeMount(async () => {});

onMounted(async () => {
  await leng();
  await playScreen();
  await aniOpacity("#corchetes-mint1", 3);
  await aniOpacity("#corchetes-mint2", 3);
  await aniOpacity("#corchetes-mint3", 3);
  await reproducir("mint-1");
  await reproducir("mint-2");
  await reproducir("mint-3");
});
</script>

<style scoped lang="scss">
.nav {
  --bs-nav-link-padding-x: 0.2rem;
  --bs-nav-link-padding-y: 0.4rem;
}
</style>
