<template>
  <NavBar :leng="datos.leng" />
  <div class="redes pt-4 espaciador">
    <div class="row">
      <div class="col-auto">
        <button type="button" class="btn" @click="redes('discord')" @keyup="redes('discord')">
          <BootstrapIcon icon="discord" size="1x" variant="secondary" />
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-auto">
        <button type="button" class="btn" @click="redes('twitter')" @keyup="redes('twitter')">
          <BootstrapIcon icon="twitter" size="1x" variant="secondary" />
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-auto">
        <button type="button" class="btn" @click="redes('medium')" @keyup="redes('medium')">
          <BootstrapIcon icon="medium" size="1x" variant="secondary" />
        </button>
      </div>
    </div>
  </div>

  <div class="container px-0 px-sm-3 px-md-4 px-lg-4 px-2 mt-xxl-4">
    <div :style="`height: ${datos.espaciador}`"></div>
    <div class="w-100 px-md-5 d-none d-sm-block">
      <div class="row">
        <div class="col-md-4 col-12 px-md-4 px-lg-5 px-xxl-5 px-3">
          <div class="w-100 px-md-4 px-lg-3">
            <p class="lh-sm text-white text-justify sofiaExtraLight
              pe-sm-2 pe-md-3 pe-lg-5 pe-2" id="horas-text" :style="`${datos.horas}`">
              How long does it take to build an hour? How can we measure
              events and the pace at which they follow one another?
              Could that be represented in a scene?
            </p>
          </div>
        </div>
        <div class="col-md-4 col-12 px-md-4 px-lg-5 px-xxl-5 px-3">
          <div class="w-100 px-md-4 px-lg-3">
            <p class="lh-sm text-white text-justify sofiaExtraLight
              pe-sm-2 pe-md-3 pe-lg-5 pe-2" id="minutos-text" :style="`${datos.minutos}`">
              Take your time to gather sixty minutes. By combining them,
              besides keeping your events, there will be a scene with
              a sequence created.
            </p>
          </div>
        </div>
        <div class="col-md-4 col-12 px-md-4 px-lg-5 px-xxl-5 px-3">
          <div class="w-100 px-md-4 px-lg-3">
            <p class="lh-sm text-white text-justify sofiaExtraLight
              pe-sm-2 pe-md-3 pe-lg-5 pe-2" id="segundos-text" :style="`${datos.segundos}`">
              You have some non fungible time in your wallet. Create an event
              by burning and combining sixty seconds value NFts.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-12 px-5">
          <div class="w-100 px-md-3 pe-md-2 pe-lg-4 pe-xxl-3 pe-1">
            <video id="horas-demo" muted preload="metadata"
              loop poster="../assets/videos/horas_demo.png">
              <source src="../assets/videos/horas_demo.mp4" type="video/mp4">
              Su navegador no soporta contenido multimedia.
              <track src="" kind="captions" srclang="es" label="">
            </video>
          </div>
        </div>
        <div class="col-md-4 col-12 px-5">
          <div class="w-100 px-md-3 pe-md-2 pe-lg-4 pe-xxl-3 pe-1 position-relative">
            <div class="w-100 position-absolute" style="z-index: 3;">
              <router-link class="nav-link pt-3 pe-md-4"
                :to="{ path: '#' }" @click="reproducir('horas')"
                @keyup="reproducir('horas')">
                <h6 class="text-white text-center futurism pe-5"
                  id="minutos" :style="`${datos.minutos}`">
                  COMBINE
                </h6>
              </router-link>
            </div>
            <video id="minutos-demo" muted preload="metadata"
              loop poster="../assets/videos/minutos_demo.png">
              <source src="../assets/videos/minutos_demo.mp4" type="video/mp4">
              Su navegador no soporta contenido multimedia.
              <track src="" kind="captions" srclang="es" label="">
            </video>
          </div>
        </div>
        <div class="col-md-4 col-12 px-5">
          <div class="w-100 px-md-3 pe-md-2 pe-lg-4 pe-xxl-3 pe-1 position-relative">
            <div class="w-100 position-absolute" style="z-index: 3;">
              <router-link class="nav-link pt-3 pe-md-4"
                :to="{ path: '#' }" @click="reproducir('minutos')"
                @keyup="reproducir('minutos')">
                <h6 class="text-white text-center futurism pe-5"
                  id="segundos" :style="`${datos.segundos}`">
                  COMBINE
                </h6>
              </router-link>
            </div>
            <video id="segundos-demo" muted preload="metadata"
              loop poster="../assets/videos/segundos_demo.png">
              <source src="../assets/videos/segundos_demo.mp4" type="video/mp4">
              Su navegador no soporta contenido multimedia.
              <track src="" kind="captions" srclang="es" label="">
            </video>
          </div>
        </div>
      </div>
    </div>
    <div class="w-100 d-flex d-sm-none">
      <div class="row">
        <div class="col-sm-6 col-12 ps-5">
          <div class="row">
            <div class="col-12">
              <div class="w-100 pe-5">
                <p class="lh-sm text-white text-justify sofiaExtraLight px-2"
                  id="segundos-text-sm"
                  :style="`${datos.segundos}`">
                  You have some non fungible time in your wallet. Create an event
                  by burning and combining sixty seconds value NFts.
                </p>
              </div>
            </div>
            <div class="col-12 position-relative">
              <div class="w-100 position-absolute pe-5">
                <div class="w-100 px-md-4 px-lg-3">
                  <h6 role="button" tabindex="0"
                    @click="reproducir('minutos-sm')" @keyup="reproducir('minutos-sm')"
                    class="text-white text-center futurism pe-4 pt-4"
                    id="segundos-sm"
                    :style="`${datos.segundos}; z-index: 10;`">
                    COMBINE
                  </h6>
                </div>
              </div>
              <video id="segundos-sm-demo" muted preload="metadata"
                loop poster="../assets/videos/segundos_demo.png">
                <source src="../assets/videos/segundos_demo.mp4" type="video/mp4">
                Su navegador no soporta contenido multimedia.
                <track src="" kind="captions" srclang="es" label="">
              </video>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-12 ps-5">
          <div class="row">
            <div class="col-12">
              <div class="w-100 pe-5">
                <p class="lh-sm text-white text-justify sofiaExtraLight px-2"
                  id="minutos-text-sm"
                  :style="`${datos.minutos}`">
                  Take your time to gather sixty minutes. By combining them,
                  besides keeping your events, there will be a scene with
                  a sequence created.
                </p>
              </div>
            </div>
            <div class="col-12 position-relative">
              <div class="w-100 position-absolute pe-5">
                <h6 role="button" tabindex="0"
                  @click="reproducir('horas-sm')" @keyup="reproducir('horas-sm')"
                  class="text-white text-center futurism pe-4 pt-4"
                  id="minutos-sm"
                  :style="`${datos.minutos}; z-index: 10;`">
                  COMBINE
                </h6>
              </div>
              <video id="minutos-sm-demo" muted preload="metadata"
                loop poster="../assets/videos/minutos_demo.png">
                <source src="../assets/videos/minutos_demo.mp4" type="video/mp4">
                Su navegador no soporta contenido multimedia.
                <track src="" kind="captions" srclang="es" label="">
              </video>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-12 ps-5">
          <div class="row">
            <div class="col-12">
              <div class="w-100 pe-5">
                <p class="lh-sm text-white text-justify sofiaExtraLight px-2"
                  id="horas-text-sm"
                  :style="`${datos.horas}`">
                  How long does it take to build an hour? How can we measure
                  events and the pace at which they follow one another?
                  Could that be represented in a scene?
                </p>
              </div>
              <video id="horas-sm-demo" muted preload="metadata"
                poster="../assets/videos/horas_demo.png">
                <source src="../assets/videos/horas_demo.mp4" type="video/mp4">
                Su navegador no soporta contenido multimedia.
                <track src="" kind="captions" srclang="es" label="">
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-4 col-12 px-sm-3 px-md-4 px-lg-4 px-xxl-5 px-2">
        <div role="button" tabindex="0" @click="textTeam()" @keyup="textTeam()"
          class="w-100">
          <p class="text-white text-center futurism pe-4"
            style="--bs-text-opacity: .50; font-size: 0.70rem;">
            ABOUT
          </p>
        </div>
      </div>
    </div>
    <div :style="`height: ${datos.espaciador}`"></div>
    <div class="row justify-content-center">
      <div class="col-auto">
        <div class="w-100 pe-4">
          <ul class="nav justify-content-center">
            <li class="nav-item ps-0">
              <a class="nav-link" href="https://www.marabiertonft.xyz" target="_blank">
                <p class="text-white text-opacity-50 futurism">
                  PAST
                </p>
              </a>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ path: '#' }">
                <p class="text-white futurism" :style="`${datos.present}`">
                  PRESENT
                </p>
              </router-link>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="http://tempoland.xyz" target="_blank">
                <p class="text-white futurism" id="future" :style="`${datos.future}`">
                  FUTURE
                </p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div :style="`height: ${datos.espaciador}`"></div>
  </div>
  <modal-container />
  <Footer2Section />
</template>

<script setup>
import NavBar from '@/components/NavBar.vue';
import Footer2Section from '@/components/Footer2Section.vue';
import {
  onBeforeMount,
  onMounted,
  reactive,
} from 'vue';
import { useRoute } from 'vue-router';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { TextPlugin } from 'gsap/TextPlugin';
import { SplitText } from 'gsap/SplitText';
import ModalConfirm from '@/components/modals/ModalConfirm.vue';
import {
  container as ModalContainer,
  openModal,
  popModal,
  // config,
} from 'jenesius-vue-modal';

gsap.registerPlugin(ScrollTrigger, TextPlugin, SplitText);
const route = useRoute();

const datos = reactive({
  leng: '',
  segundos: '--bs-text-opacity: .12;',
  minutos: '--bs-text-opacity: .12;',
  horas: '--bs-text-opacity: .12;',
  present: '--bs-text-opacity: .75;',
  future: '--bs-text-opacity: .5;',
  tlSegundos: null,
  tlMinutos: null,
  tlHoras: null,
  espaciador: 0,
  timer: null,
  commingsoon: true,
});

const leng = async () => {
  if (route.params.leng === 'zh') {
    datos.leng = 'zh';
  }
};

const redes = async (red) => {
  if (red === 'discord') {
    window.open('https://discord.gg/sDhh8yxR', '_blank');
  }
  if (red === 'twitter') {
    window.open('https://twitter.com/_NFTime_', '_blank');
  }
  // if (red === 'medium') { }
};

const textTeam = async () => {
  let label = '';
  let textI = '';
  let textII = '';
  let textIII = '';
  let textIV = '';

  label = 'THIS IS NOT A WATCH';
  textI = `
    A new temporal ecosystem begins. We, creatures made of time, are
    organizing to be masters of our future. Non fungible time is a
    collection of digital files representing moments, happenings,
    occurrences, physics or a wide variety of events
  `;
  textII = `
    The usual measurement of time in hours, minutes and seconds turns
    into a sequence of animated tri-dimensional objects that are
    created and combined when their owners decide so. The number
    representing seconds will eventually become the collapse of a
    glacier, gravity, a birth, the ripening of a fruit, the sound of rain on a
    roof or a wormhole; scenes for our large project of the world of time.
  `;
  textIII = `
    Moving forwards always requires leaving something behind. We blockchain
    time which is non-fungible and at the same time we burn objects to build it.
    Time goes by and not always forwards. It is not linear nor does it
    advance along a single trajectory. Events follow one after another
    and we keep travelling with them from the past to the future and back to the past.
  `;
  textIV = `
    In Non Fungible Time, we will decide together what to do with time.
    How much time do you have or need? How much time do you want to dedicate to the future?
  `;

  function resolveConfirm() {
    popModal();
  }
  openModal(
    ModalConfirm,
    {
      label,
      textI,
      textII,
      textIII,
      textIV,
      resolve: resolveConfirm,
    },
  );
};

const animacionesTempoland = async () => {
  const tempoland = gsap.utils.toArray('.anime-tempoland');
  tempoland.forEach((tl) => {
    const anim = gsap.from(tl, { opacity: 0, y: 100, duration: 1 });
    ScrollTrigger.create({
      trigger: tl,
      animation: anim,
      toggleActions: 'restart complete none none',
      once: true,
    });
  });
};

const aniWhite = async (obj, s) => {
  const tl = gsap.timeline();
  tl.fromTo(
    obj,
    { opacity: 0, ease: 'none' },
    { opacity: 1, duration: s },
  );
};

const aniOpacity = async (obj, s) => {
  const tl = gsap.timeline({ repeat: -1 });
  tl.fromTo(
    obj,
    { opacity: 0.5, ease: 'none' },
    { opacity: 1, duration: s },
  );
  tl.fromTo(
    obj,
    { opacity: 1, ease: 'none' },
    { opacity: 0.5, duration: s },
  );
  if (obj === '#segundos-text') {
    datos.tlSegundos = tl;
  }
  if (obj === '#minutos-text') {
    datos.tlMinutos = tl;
  }
  if (obj === '#horas-text') {
    datos.tlHoras = tl;
  }
};

const aniOpacityPause = async (obj) => {
  if (obj === '#segundos-text') {
    datos.tlSegundos.pause();
  }
  if (obj === '#minutos-text') {
    datos.tlMinutos.pause();
  }
  if (obj === '#horas-text') {
    datos.tlHoras.pause();
  }
};

const aniSplitText = async (ani, obj, eliminar = false) => {
  const id = document.getElementById(obj);
  let repeat = -1;
  if (eliminar) {
    repeat = 0;
  }
  // eslint-disable-next-line
  const mySplitText = new SplitText(id, { type: 'words' });
  const splitTextTimeline = gsap.timeline({ repeat });
  gsap.set(id, { perspective: 400 });

  const kill = async () => {
    splitTextTimeline.clear().time(0);
    mySplitText.revert();
  };

  if (ani === 'chars') {
    kill();
    mySplitText.split({ type: 'chars, words' });
    splitTextTimeline.from(
      mySplitText.chars,
      {
        duration: 0.6,
        scale: 4,
        autoAlpha: 0,
        rotationX: -180,
        transformOrigin: '100% 50%',
        ease: 'back',
        stagger: 0.02,
      },
    );
  }
  if (ani === 'words') {
    kill();
    mySplitText.split({ type: 'words' });
    mySplitText.words.forEach((el, index) => {
      splitTextTimeline.from(el, { duration: 0.4, opacity: 0.9, force3D: true }, index * 0.01);
      splitTextTimeline.from(el, { duration: 0.7, scale: index % 2 === 0 ? 2 : 2 }, index * 0.01);
    });
  }
  if (ani === 'lines') {
    kill();
    mySplitText.split({ type: 'lines' });
    splitTextTimeline.from(
      mySplitText.lines,
      {
        duration: 0.5,
        opacity: 0,
        rotationX: -120,
        force3D: true,
        transformOrigin: 'top center -150',
        stagger: 0.1,
      },
    );
  }
  if (ani === 'charsWordsLines') {
    kill();
    mySplitText.split({ type: 'chars, words, lines' });
    splitTextTimeline.from(
      mySplitText.chars,
      {
        duration: 0.2,
        autoAlpha: 0,
        scale: 4,
        force3D: true,
        stagger: 0.01,
      },
      0.5,
    )
      .to(
        mySplitText.words,
        {
          duration: 0.1,
          color: '#8FE402',
          scale: 0.9,
          stagger: 0.1,
        },
        'words',
      )
      .to(
        mySplitText.words,
        {
          duration: 0.2,
          color: 'white',
          scale: 1,
          stagger: 0.1,
        },
        'words+=0.1',
      )
      .to(
        mySplitText.lines,
        {
          duration: 0.5,
          x: 100,
          autoAlpha: 0,
          stagger: 0.2,
        },
      );
  }

  // revert the text back to its pre-split state
  if (ani === 'revert') {
    mySplitText.revert();
  }
};

/* const detener = async (num) => {
  const pause = document.getElementById(`${num}-demo`);
  if (pause) {
    await pause.pause();
    await pause.load();
  }
}; */

const reproducir = async (num) => {
  const play = document.getElementById(`${num}-demo`);
  if (num === 'segundos') {
    setTimeout(async () => {
      datos.segundos = '--bs-text-opacity: .75;';
      await aniOpacityPause('#segundos-text');
      await aniWhite('#segundos-text', 10);
    }, 3000);
  }
  if (num === 'minutos') {
    datos.segundos = '--bs-text-opacity: .12;';
    datos.minutos = '--bs-text-opacity: .75;';
    datos.horas = '--bs-text-opacity: .12;';
    await aniOpacityPause('#minutos');
    await aniOpacity('#segundos', 3);
    await aniOpacity('#minutos', 0.7);
    await aniOpacity('#segundos-text', 3);
    await aniOpacityPause('#minutos-text');
    await aniWhite('#minutos-text', 10);
  }
  if (num === 'horas') {
    datos.segundos = '--bs-text-opacity: .12;';
    datos.minutos = '--bs-text-opacity: .12;';
    datos.horas = '--bs-text-opacity: .75;';
    await aniOpacityPause('#minutos');
    await aniOpacity('#minutos-text', 3);
    await aniOpacity('#minutos', 3);
    await aniOpacityPause('#horas-text');
    await aniWhite('#horas-text', 10);
    play.addEventListener('timeupdate', async () => {
      if (play.currentTime >= 49) {
        await aniSplitText('opacity', 'horas', true);
        datos.present = '--bs-text-opacity: .5;';
        datos.future = '--bs-text-opacity: .80;';
        await aniOpacity('#future', 0.7);
      }
    });
  }

  if (num === 'segundos-sm') {
    setTimeout(async () => {
      datos.segundos = '--bs-text-opacity: .75;';
      await aniOpacityPause('#segundos-text-sm');
      await aniWhite('#segundos-text-sm', 10);
    }, 3000);
  }
  if (num === 'minutos-sm') {
    datos.segundos = '--bs-text-opacity: .12;';
    datos.minutos = '--bs-text-opacity: .75;';
    datos.horas = '--bs-text-opacity: .12;';
    await aniOpacityPause('#minutos-sm');
    await aniOpacity('#segundos-sm', 3);
    await aniOpacity('#minutos-sm', 0.7);
    await aniOpacity('#segundos-text-sm', 3);
    await aniOpacityPause('#minutos-text-sm');
    await aniWhite('#minutos-text-sm', 10);
  }
  if (num === 'horas-sm') {
    datos.segundos = '--bs-text-opacity: .12;';
    datos.minutos = '--bs-text-opacity: .12;';
    datos.horas = '--bs-text-opacity: .75;';
    await aniOpacityPause('#minutos-sm');
    await aniOpacity('#minutos-text-sm', 3);
    await aniOpacity('#minutos-sm', 3);
    await aniOpacityPause('#horas-text-sm');
    await aniWhite('#horas-text-sm', 10);
    play.addEventListener('timeupdate', async () => {
      if (play.currentTime >= 49) {
        await aniSplitText('opacity', 'horas', true);
        datos.present = '--bs-text-opacity: .5;';
        datos.future = '--bs-text-opacity: .80;';
        await aniOpacity('#future', 0.7);
      }
    });
  }
  if (play) {
    if (num === 'segundos') {
      setTimeout(async () => {
        await play.play();
      }, 5000);
    } else {
      await play.play();
    }
  }
};

const tScreen = (async () => {
  datos.espaciador = '0vh';
  const height = document.documentElement.scrollHeight;
  if (height > 668 && height < 767.99) {
    datos.espaciador = '1.5vh';
  } else if (height >= 768 && height < 899.99) {
    datos.espaciador = '2vh';
  } else if (height >= 900 && height < 1023.99) {
    datos.espaciador = '5vh';
  } else if (height >= 1024 && height < 1151.99) {
    datos.espaciador = '7vh';
  } else if (height >= 1152 && height < 1200) {
    datos.espaciador = '9vh';
  } else if (height >= 1200.99 && height < 1300) {
    datos.espaciador = '11vh';
  } else if (height >= 1300.99) {
    datos.espaciador = '13vh';
  }
});

const playScreen = (async () => {
  if (datos.timer === null) {
    tScreen();
    datos.timer = setInterval(() => tScreen(), 1000);
  } else {
    clearInterval(datos.timer);
    datos.timer = null;
  }
});

onBeforeMount(async () => {
});

onMounted(async () => {
  await leng();
  await playScreen();
  await animacionesTempoland();
  await aniOpacity('#segundos-text', 3);
  await aniOpacity('#minutos-text', 3);
  await aniOpacity('#horas-text', 3);
  await aniOpacity('#segundos', 0.7);
  await aniOpacity('#segundos-sm', 0.7);
  await aniOpacity('#minutos', 3);
  await aniOpacity('#minutos-sm', 3);
  await reproducir('segundos');
  await reproducir('segundos-sm');
});
</script>

<style scoped lang="scss">
.nav {
  --bs-nav-link-padding-x: 0.2rem;
  --bs-nav-link-padding-y: 0.4rem;
}
</style>
