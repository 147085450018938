import { createApp } from 'vue';
import intersectionTracker from 'vue-intersection-tracker';
import ProgressBarSpinner from '@honk1/vue-scroll-progressbar';
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// eslint-disable-next-line
import 'keen-slider/keen-slider.min.css';
import './styles/main.scss';

createApp(App)
  .use(store)
  .use(router)
  .use(intersectionTracker)
  .component('v-loader', ProgressBarSpinner)
  .component('BootstrapIcon', BootstrapIcon)
  .mount('#app');
