<template>
  <div>
    <NavBar :leng="''" />
    <div class="container row text-white justify-content-center">
      <form class="col-auto mt-5">
        <div>
          <p>Ingresa la Address del contrato</p>
          <label for="code">
            <input type="text" id="code" v-model="contractAddress" />
          </label>
        </div>
      </form>
      <div class="col-auto">
        <div class="wrap-buttons mt-5">
          <button @click="connect()">Connect</button>
        </div>

        <!-- <div class="wrap-buttons mt-5">
          <button @click="Toggle()">Toggle Combine</button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import NavBar from "@/components/NavBar.vue";
import { ref } from "vue";
// eslint-disable-next-line no-unused-vars
import { connectContrat, toggleCombine } from "@/utilities/web3";
import { useStore } from "vuex";

const store = useStore();

const contractAddress = ref("");

const connect = async () => {
  try {
    const contract = await connectContrat(contractAddress.value);
    store.commit("setContract", contract);
  } catch (error) {
    console.error(error);
    // eslint-disable-next-line no-alert
    alert("error al intentar conectar");
  }
}; /*
const Toggle = async () => {
  try {
    await toggleCombine(contractAddress.value);
  } catch (error) {
    console.error(error);
    // eslint-disable-next-line no-alert
    alert("error al intentar conectar");
  }
}; */
</script>

<style scoped lang="scss">
.nav {
  --bs-nav-link-padding-x: 0.2rem;
  --bs-nav-link-padding-y: 0.4rem;
}
</style>
