<!-- eslint-disable vuejs-accessibility/label-has-for -->
<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="modal-window ajust-overflow">
    <div class="row justify-content-center text-white">
      <div class="col-auto ajust-overflow">
        <div class="form-check" v-for="token in list" v-bind:key="token.token_id">
          <input
            class="form-check-input"
            type="checkbox"
            v-bind:value="token.token_id"
            v-bind:id="token.token_id"
            v-model="tokens"
          />
          <label class="form-check-label" v-bind:for="token.token_id">
            Token: {{ token.token_id }}, value: {{ token.attributes[0].value }}
          </label>
        </div>
        <span>Tokens selected: {{ tokens.toString() }}</span>
      </div>

      <div class="wrap-buttons text-center mt-4 mb-5">
        <p>the sum of the value of the tokens must be 60 points</p>
        <!-- <button @click="emit('close')">Close</button> -->
        <button class="btn btn-light" @click="CombineToken()">Accept</button>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-alert */
/* eslint-disable object-curly-newline */
import { ref, onMounted } from "vue";
import { init, combineToken, ReCombineToken, consultTokens } from "../../utilities/web3";

const tokens = ref([]);
const list = ref([]);
const tokenId = ref('');
const url = ref(
  // eslint-disable-next-line comma-dangle
  "https://testnets.opensea.io/assets/goerli/0x1452dfDa94f7a996De5bd95AfCE861142BeC3749/"
);
const props = defineProps({
  type: {
    type: String,
  },
});
function viewToken(id) {
  tokenId.value = Number(id) - 1;
  if (
    // eslint-disable-next-line no-restricted-globals
    confirm("Merge exitoso, si acepta se abrira una nueva pestaña donde podra verlo")
  ) {
    // eslint-disable-next-line no-restricted-globals
    open(url.value + tokenId.value);
  }
}
const CombineToken = async () => {
  const myList = tokens.value.map((a) => a);
  console.log(myList);
  if (props.type === "minute") {
    combineToken(myList)
      .then((id) => {
        viewToken(id);
      })
      .catch(() => alert("Ocurrio un error al intentar Combinar"));
  } else {
    ReCombineToken(myList)
      .then(() => alert("merge exitoso"))
      .catch(() => alert("Ocurrio un error al intentar Combinar"));
  }
};

const getTokens = async () => {
  await init().then(async () => {
    // eslint-disable-next-line no-return-assign
    await consultTokens(props.type === "hour").then((data) => {
      list.value = data;
      // eslint-disable-next-line no-return-assign, no-param-reassign
      list.value.forEach((t) => (t.token_id));
    });
  });
};
onMounted(async () => {
  await getTokens();
});
</script>

<style scoped lang="css">
.ajust-overflow{
  margin-top: 4rem;
  overflow:auto;
  height: 100vh;
}
.modal-window {
  padding: 1rem;
  background: rgba(0, 0, 0, 1);
  border-radius: 0;
  min-width: 33%;
  max-width: 33%;
  margin-top: 4rem;
  overflow:auto;
  height: 100vh;
}

@media (min-width: 355px) and (max-width: 575.98px) {
  .modal-window {
    min-width: 75%;
    max-width: 75%;
  }
}
</style>
