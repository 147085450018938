import { createStore } from 'vuex';

export default createStore({
  state: {
    contract: {},
  },
  getters: {
  },
  mutations: {
    setContract(state, contract) {
      state.contract = contract;
    },
  },
  actions: {
  },
  modules: {
  },
});
