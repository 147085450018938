/* eslint-disable function-paren-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import Web3 from "web3";

let selectedAccount;
let nftContract;
let NFTContractBuild;
const baseUrl = "https://api.nonfungibletime.io/api/"; // "http://localhost:3000/api/"; //
const contractAddress = "0x1452dfDa94f7a996De5bd95AfCE861142BeC3749";

export async function connectContrat() {
  const res = await fetch("/NewTime.json").then((response) => response.json());
  NFTContractBuild = res;
  if (NFTContractBuild) {
    const provider = window.ethereum;
    const web3 = new Web3(provider);
    nftContract = await new web3.eth.Contract(NFTContractBuild.abi, contractAddress);
    console.log(nftContract);
    return nftContract;
  }
  throw new Error("Error al conectar el contrato");
}
export const init = async () => {
  connectContrat();
  const provider = window.ethereum;
  // eslint-disable-next-line no-unused-vars
  if (typeof provider !== "undefined") {
    provider
      .request({ method: "eth_requestAccounts" })
      .then((accounts) => {
        // eslint-disable-next-line prefer-destructuring
        selectedAccount = accounts[0];
        console.log(selectedAccount);
      })
      .catch((err) => {
        console.log(err);
      });
    window.ethereum.on("accountsChanged", (accounts) => {
      // eslint-disable-next-line prefer-destructuring
      selectedAccount = accounts[0];
      console.log(`Selected account changed to ${selectedAccount}`);
    });
  }
  return selectedAccount;
};

export const mintToken = async (quantity, contract) => {
  // nftContract = contract || nftContract;
  const provider = window.ethereum;
  const web3 = new Web3(provider);
  const tokenValue = await nftContract.methods.price().call();
  const price = quantity * Number(tokenValue);
  const gasPrice = await web3.eth.getGasPrice();
  const estimate = await nftContract.methods.mint(quantity).estimateGas({
    from: selectedAccount,
    value: price,
  });
  await nftContract.methods.mint(quantity).send({
    from: selectedAccount,
    value: price,
    gasPrice: gasPrice + estimate,
  });
  const tokenID = await nftContract.methods.countToken().call();
  const token = await nftContract.methods.times(Number(tokenID) - 1).call();
  console.log(token);
  const body = {
    token_id: Number(tokenID) - 1,
    owner: selectedAccount,
    value: Number(token.value),
  };

  await fetch(`${baseUrl}tokens`, {
    method: "POST",
    body: JSON.stringify(body),
  });
  await fetch(`${baseUrl}token-count`, {
    method: "POST",
    body: JSON.stringify({ quantity }),
  });
  return tokenID;
};
export const combineToken = async (list) => {
  const provider = window.ethereum;
  const web3 = new Web3(provider);
  const estimateGas = await web3.eth.estimateGas({ from: selectedAccount });
  const gasPrice = await web3.eth.getGasPrice();

  console.log("estimated", estimateGas);
  try {
    await nftContract.methods.combine(list).send({
      from: selectedAccount,
      gasPrice: gasPrice + estimateGas,
    });
    const tokenID = await nftContract.methods.countToken().call();
    const tokens = {
      token_id: Number(tokenID) - 1,
      listId: list,
      owner: selectedAccount,
      event_id: Number(tokenID) - 1,
      burned: true,
    };

    await fetch(`${baseUrl}tokens`, {
      method: "POST",
      body: JSON.stringify(tokens),
    });
    return tokenID;
  } catch (err) {
    console.log(err);
    throw new Error(err.message);
  }
};

export const ReCombineToken = async (list) => {
  const provider = window.ethereum;
  const web3 = new Web3(provider);
  const estimateGas = await web3.eth.estimateGas({ from: selectedAccount });
  const gasPrice = await web3.eth.getGasPrice();

  console.log("estimated", estimateGas);
  await nftContract.methods.combine(list).send({
    from: selectedAccount,
    gasPrice: gasPrice * estimateGas,
  });
  const tokens = {
    listId: list,
    burned: true,
  };

  await fetch(`${baseUrl}tokens`, {
    method: "PUT",
    body: JSON.stringify(tokens),
  });
};

export const toggleCombine = async () => {
  const provider = window.ethereum;
  const web3 = new Web3(provider);
  await nftContract.methods.toggleCombinable().send({ from: selectedAccount });
};

export const consultTokens = async (minutes = false) => {
  const tokens = await fetch(
    `${baseUrl}tokens-by-owner?owner=${selectedAccount}&minutes=${minutes}`,
    {
      method: "GET",
    },
  ).then((data) => data.json());
  return tokens;
};

export async function setNft() {
  const count = await nftContract.methods.countToken().call();
  console.log(count);
  // eslint-disable-next-line no-plusplus
  for (let index = 151; index < Number(count); index++) {
    // eslint-disable-next-line no-await-in-loop
    const element = await nftContract.methods.times(index).call();
    console.log(element);
    const body = {
      token_id: Number(element.id),
      owner: "0x84F4a5CF713ACf4C1B72D8B32734fbc17c817167",
      value: Number(element.value),
    };
    // eslint-disable-next-line no-await-in-loop
    await fetch(`https://api.nonfungibletime.io/api/tokens`, {
      method: "POST",
      body: JSON.stringify(body),
    });
  }
}
