import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import MintView from '../views/MintView.vue';
import TermView from '../views/TermView.vue';
import ConfigView from '../views/ConfigView.vue';

const routes = [
  /* {
    path: '/',
    redirect: '/home',
  }, */
  {
    path: '/',
    name: 'home',
    component: HomeView,
    props: true,
  },
  {
    path: '/mint',
    name: 'MintView',
    component: MintView,
    props: true,
  },
  {
    path: '/termsAndConditions',
    name: 'TermView',
    component: TermView,
    props: true,
  },
  {
    path: '/config-contract',
    name: 'Config',
    component: ConfigView,
    props: true,
  },
];
// component: () => import('../views/AboutView.vue'),
const router = createRouter({
  history: createWebHistory(),
  routes,
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }
    return { top: 0 };
  },
});
export default router;
