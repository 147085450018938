<template>
  <!-- <div class="grow"></div> -->
  <router-view />
</template>

<script>
// import NavBar from '@/components/NavBar.vue';

export default {
  name: 'App',
  components: {
    // NavBar,
  },
};
</script>

<style scoped lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  min-width: 100vw;
  /* overflow-x: hidden; */
  /* overflow-y: scroll; */
}
</style>
