// import { ref, readonly } from 'vue';
import anime from 'animejs/lib/anime.es';

class Animation {
  AnimeImg = (el, done) => {
    anime({
      targets: el,
      opacity: [0, 1],
      translateX: [300, 0],
      duration: 1000,
      easing: 'easeOutElastic',
      delay: el.dataset.index * 100, // build a custom delay based on the index
      complete: done,
    });
  }

  onEnter = (el, done) => {
    anime({
      targets: el,
      opacity: [0, 1],
      translateX: [300, 0],
      duration: 1000,
      easing: 'easeOutElastic',
      delay: el.dataset.index * 100, // build a custom delay based on the index
      complete: done,
    });
  }

  // called when the leave transition starts.
  // use this to start the leaving animation.
  onLeave = (targets, done) => {
    anime({
      targets,
      opacity: [1, 0],
      translateY: [0, 300],
      duration: 500,
      easing: 'easeOutCubic',
      delay: (this.totalCards - targets.dataset.index) * 50, // reverse our custom delay
      complete: done,
    });
  }

  onEnterTop = (targets, duration, easing) => {
    anime.set(targets, { translateY: '-101%' });
    anime({
      targets,
      delay: (el, i) => duration / 12.6 + i * 40,
      translateY: {
        value: 0,
        duration,
        easing,
      },
    });
  }

  onEnterLet = (targets, duration, easing) => {
    anime.set(targets, { translateX: '-101%' });
    anime({
      targets,
      delay: (el, i) => duration / 12.6 + i * 40,
      translateX: {
        value: 0,
        duration,
        easing,
      },
    });
  }

  onEnterRight = (sections, duration, easing) => {
    anime.set(sections, { translateX: '101%' });
    anime({
      targets: sections,
      delay: (el, i) => duration / 12.6 + i * 40,
      translateX: {
        value: 0,
        duration,
        easing,
      },
    });
  }

  onEnterBottom = (targets, duration, easing) => {
    anime.set(targets, { translateY: '101%' });
    anime({
      targets,
      delay: (el, i) => duration / 12.6 + i * 40,
      translateY: {
        value: 0,
        duration,
        easing,
      },
    });
  }

  enterBottom = (targets) => {
    anime({
      targets,
      translateY: [100, 0],
      duration: 1200,
      delay: (el, i) => {
        const d = 1000 + 100 * i;
        return d;
      },
    });
  }

  WordShuffler = (hol, opt, gray = false, date = false) => {
    let holder = hol;
    let then = Date.now();
    let currentTimeOffset = 0;
    let currentWord = [];
    let currentCharacter = 0;
    let currentWordLength = 0;
    let colores = [];
    let chars = [];
    let specialCharacters = [];

    if (gray) {
      const grays = [
        '#f8f9fa',
        '#e9ecef',
        '#dee2e6',
        '#ced4da',
        '#adb5bd',
        '#6c757d',
        '#495057',
        '#343a40',
        '#212529',
      ];
      colores = grays;
    } else {
      const colors = [
        '#f44336', '#e91e63', '#9c27b0',
        '#673ab7', '#3f51b5', '#2196f3',
        '#03a9f4', '#00bcd4', '#009688',
        '#4caf50', '#8bc34a', '#cddc39',
        '#ffeb3b', '#ffc107', '#ff9800',
        '#ff5722', '#795548', '#9e9e9e',
        '#607d8b',
      ];
      colores = colors;
    }
    const options = {
      fps: 29,
      timeOffset: 200,
      textColor: '#000',
      useCanvas: false,
      mixCapital: false,
      mixSpecialCharacters: false,
      needUpdate: false,
      colors: colores,
    };

    if (typeof opt !== 'undefined') {
      Object.keys(opt).forEach((key) => {
        options[key] = opt[key];
      });
    }

    let needUpdate = true;
    const {
      fps,
      timeOffset,
      textColor,
      mixCapital,
      mixSpecialCharacters,
      useCanvas,
      colors,
    } = options;
    const interval = fps;

    if (date) {
      chars = [
        '1', '2', '3',
        '4', '4', '5',
        '6', '7', '8',
        '9', '0',
      ];
    } else {
      chars = [
        '1', '2', '3', '4',
        '5', '6', '7', '8',
        '9', '0', 'T', 'E', 'M', 'P', 'O', 'L', 'A', 'N', 'D',
      ];
    }

    specialCharacters = [
      ':', ' ', '-', '/',
    ];

    if (mixSpecialCharacters) {
      chars = chars.concat(specialCharacters);
    }

    const getRandomColor = () => {
      const randNum = Math.floor(Math.random() * colors.length);
      return colors[randNum];
    };

    // if DOM
    if (typeof hol !== 'undefined') {
      holder = hol;
    }

    if (!useCanvas && typeof hol === 'undefined') {
      console.warn('Holder must be defined in DOM Mode. Use Canvas or define Holder');
    }

    const getRandCharacter = (characterToReplace) => {
      if (characterToReplace === '') {
        return '';
      }
      const randNum = Math.floor(Math.random() * chars.length);
      const lowChoice = -0.5 + Math.random();
      const picketCharacter = chars[randNum];
      let choosen = picketCharacter.toLowerCase();
      if (mixCapital) {
        choosen = lowChoice < 0 ? picketCharacter.toLowerCase() : picketCharacter;
      }
      return choosen;
    };

    const writeWord = (word) => {
      currentWord = word.split('');
      currentWordLength = currentWord.length;
    };

    const generateSingleCharacter = (color, character) => {
      const span = document.createElement('span');
      span.style.color = color;
      span.innerHTML = character;
      return span;
    };

    const updateCharacter = () => {
      const now = Date.now();
      const delta = now - then;

      if (delta > interval) {
        currentTimeOffset += 1;

        const word = [];

        if (
          currentTimeOffset === timeOffset
          && currentCharacter !== currentWordLength
        ) {
          currentCharacter += 1;
          currentTimeOffset = 0;
        }

        for (let k = 0; k < currentCharacter; k += 1) {
          word.push(currentWord[k]);
        }

        for (let i = 0; i < currentWordLength - currentCharacter; i += 1) {
          word.push(getRandCharacter(currentWord[currentCharacter + i]));
        }

        if (!useCanvas) {
          if (currentCharacter === currentWordLength) {
            needUpdate = false;
          }
          holder.innerHTML = '';
          word.forEach((w, index) => {
            let color = null;
            if (index > currentCharacter) {
              color = getRandomColor();
            } else {
              color = textColor;
            }
            holder.appendChild(generateSingleCharacter(color, w));
          });
        }
        then = now - (delta % interval);
      }
    };

    const update = (time) => {
      if (needUpdate) {
        updateCharacter(time);
      }
      requestAnimationFrame(update);
    };

    writeWord(holder.innerHTML);
    update(0);
  }
}
export default new Animation();
