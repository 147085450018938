<template>
  <div class="w-100 bg-black bg-opacity-10">
    <nav class="navbar navbar-expand-lg navbar-black bg-black bg-opacity-10">
      <div class="container-fluid px-4">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <router-link to="" role="button"
              class="nav-link futurism anime-titles-navbar">
              © CROONOS LAB SL.
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/termsAndConditions" role="button"
              class="nav-link futurism anime-titles-navbar">
              | TERM AND CONDITIONS
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script setup>
import {
  onBeforeMount,
  onMounted,
  // reactive,
} from 'vue';

onBeforeMount(() => {
});

onMounted(() => {
});
</script>

<style scoped lang="scss">
.border-warning {
  --bs-border-opacity: 0.5 !important;
  --bs-warning-rgb: 255, 255, 0;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}
</style>
