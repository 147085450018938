<template>
  <div class="w-100 sticky-top px-0 pt-2 bg-black bg-opacity-10">
    <nav class="navbar navbar-expand-lg navbar-black bg-black bg-opacity-10">
      <div class="container-fluid px-4">
        <a
          href="https://nonfungibletime.io"
          class="nav-link badge text-white text-start text-wrap futurism fs-6"
          style="width: 4.6rem; --bs-text-opacity: 0.7"
        >
          NON FUNGIBLE TIME
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div v-if="props.leng === 'zh'" class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <router-link :to="{ path: '/' }" class="nav-link futurism anime-titles-navbar">
                Demo
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ path: '/' }" class="nav-link futurism anime-titles-navbar">
                Mint
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ path: '/' }" class="nav-link futurism anime-titles-navbar">
                Time Items
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                to=""
                @click.prevent="showOffcanvasMenu(true)"
                role="button"
                class="nav-link futurism anime-titles-navbar"
              >
                Connect Wallet
              </router-link>
            </li>
          </ul>
        </div>
        <div v-else class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <router-link :to="{ path: '/' }" class="nav-link futurism anime-titles-navbar active">
                | Demo
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ path: '/mint' }"
                role="button"
                class="nav-link futurism anime-titles-navbar"
                id="mint"
              >
                | Mint
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                to=""
                role="button"
                @click="commingSoon('#time-items')"
                @keyup="commingSoon('#time-items')"
                class="nav-link futurism anime-titles-navbar"
                id="time-items"
              >
                | Time Items
              </router-link>
            </li>
          </ul>
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link
                to=""
                @click.prevent="connectWallet()"
                role="button"
                class="nav-link futurism anime-titles-navbar"
              >
                | {{  wallet || "Connect Wallet" }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div
      class="offcanvas offcanvas-start"
      :class="datos.showMenu ? 'show' : ''"
      tabindex="-1"
      :style="{ visibility: datos.showMenu ? 'visible' : 'hidden' }"
    >
      <div class="offcanvas-header">
        <h5 class="offcanvas-title">Coming Soon</h5>
        <button
          type="button"
          class="btn-close text-reset"
          @click.prevent="showOffcanvasMenu(false)"
        ></button>
      </div>
      <div class="offcanvas-body">
        <p>.....</p>
      </div>
    </div>
  </div>
</template>

<script setup>
// eslint-disable-next-line object-curly-newline
import { onBeforeMount, onMounted, reactive, ref } from "vue";
import Animation from "@/composables/Animate";
import tippy from "tippy.js";
import { init } from "../utilities/web3";

const props = defineProps({
  leng: {
    type: String,
  },
});
const wallet = ref("");

const datos = reactive({
  showMenu: false,
});

const showOffcanvasMenu = (status) => {
  datos.showMenu = status;
};

const commingSoon = (tag) => {
  tippy(tag, {
    content: `
      <p class="text-white text-opacity-50 futurism">Coming Soon</p>
    `,
    allowHTML: true,
    trigger: "click",
    placement: "top",
  });
};

const animacionesTextNavbar = () => {
  const titlesHome = document.querySelectorAll(".anime-titles-navbarll");
  titlesHome.forEach((text) => {
    Animation.WordShuffler(
      text,
      {
        textColor: "rgba(255,255,255,0.7)",
        timeOffset: 2,
        mixCapital: true,
        mixSpecialCharacters: true,
      },
      true,
    );
  });
  const parrafoHome = document.querySelectorAll(".anime-text-navbar");
  parrafoHome.forEach((text) => {
    Animation.WordShuffler(
      text,
      {
        textColor: "rgba(255,255,255,0.7)",
        timeOffset: 1,
      },
      true,
    );
  });
};
const connectWallet = () => {
  // eslint-disable-next-line no-console
  init()
    .then((address) => {
      wallet.value = "".concat(address.slice(0, 6), "...", address.slice(34));
    })
    .catch((err) => console.info(err));
};

onBeforeMount(() => {});

onMounted(() => {
  animacionesTextNavbar();
});
</script>

<style scoped lang="scss"></style>
