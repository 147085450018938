<template>
  <NavBar :leng="datos.leng" />
  <div class="container px-0 px-sm-3 px-md-4 px-lg-4 px-2 mt-xxl-4">
    <div class="row justify-content-center">
      <div class="col-md-6 col-12 py-5">
        <h2 class="lh-sm text-white futurism mb-0">Terms</h2>
        <h2 class="lh-sm text-white futurism mb-0">and</h2>
        <h2 class="lh-sm text-white futurism mb-5">Conditions</h2>
        <div class="w-100 pe-3" style="max-height: 37vh; overflow-y: scroll">
          <p class="lh-sm text-white text-justify sofiaExtraLight" style="`${datos.horas}`">
            This document constitutes the legal notice and conditions that regulate the access, use,
            browsing, purchase, and utilization of the website located at the URL nonfungibletime.io
            ("The Site"). Please read these Terms of Use (hereinafter referred to as "the Terms")
            and our Privacy Policy carefully as they govern your use of the website, its content,
            and the functionalities accessible through "The Site" offered by Croonos Lab S.L
            ("Croonos Lab"), a Spanish company, with registered address at C/ Calderón de la Barca
            3, 3C, 28013 Madrid, and Tax ID: B-87891982, registered in the Commercial Registry of
            Madrid with the following registration details: Volume: 36226, Book: 0, Folio: 147,
            Section: 8, Sheet: M 650981, Entry or Annotation: 1, and dated: 28/07/2017 (hereinafter
            referred to as "Croonos Lab").
          </p>
          <p class="lh-sm text-white text-justify sofiaExtraLight" style="`${datos.horas}`">
            1. Agreement to Terms. By using our Site, you agree to be bound by these Terms. If you
            do not agree to these Terms, do not use the Site.
          </p>
          <p class="lh-sm text-white text-justify sofiaExtraLight" style="`${datos.horas}`">
            2. Privacy Policy. Please review our Privacy Policy, which also governs your use of the
            Site, to understand how we collect, use, and share your information.
          </p>
          <p class="lh-sm text-white text-justify sofiaExtraLight" style="`${datos.horas}`">
            3. Changes to these Terms or the Site. We may update the Terms from time to time at our
            sole discretion. If we do so, we will notify you by posting the updated Terms on the
            Site. It is important that you review the Terms each time we update them or use the
            Site. If you continue to use the Site after we have posted the updated Terms, it means
            you accept and agree to the changes. If you do not agree to be bound by the changes, you
            will no longer be able to use the Site. We may change or discontinue all or any part of
            the Site at any time and without prior notice at our sole discretion.
          </p>
          <p class="lh-sm text-white text-justify sofiaExtraLight" style="`${datos.horas}`">
            4. Who can use the site? You may use the Site only if you are 18 years or older and
            capable of entering into a binding contract with Croonos Lab, and you are not prohibited
            from using the Site under applicable law. Feedback: we value your feedback about the
            Site, but please do not submit unsolicited suggestions for improvements, creative ideas,
            designs, presentation folders, or any other materials (collectively, "Unsolicited
            Ideas"). This policy is aimed at avoiding potential disputes or misunderstandings when
            our Site may appear similar to Unsolicited Ideas sent by individuals. We may currently
            be developing, have developed, or may develop in the future ideas or materials
            internally or receive ideas or materials from other parties that may be similar to
            Unsolicited Ideas. If you disregard this policy and still submit your Unsolicited Ideas
            to us, you grant us a non-exclusive, worldwide, perpetual, irrevocable, fully paid,
            royalty-free, sublicensable, and transferable license under all intellectual property or
            other rights that you own or control to use, copy, modify.
          </p>
          <p class="lh-sm text-white text-justify sofiaExtraLight" style="`${datos.horas}`">
            5. Intellectual and Industrial Property: Croonos Lab is the owner or licensee of all
            Industrial and Intellectual Property rights of all the content and objects published on
            the website, especially the designs, texts, graphics, logos, icons, buttons, software,
            and any other sign capable of industrial and commercial use. The assignment,
            distribution, transmission, storage, or total or partial communication of the content of
            the website nonfungibletime.io is prohibited.
          </p>
          <p class="lh-sm text-white text-justify sofiaExtraLight" style="`${datos.horas}`">
            6. General Prohibitions and Croonos Lab's Enforcement Rights. You agree not to do any of
            the following: (a) Use, display, mirror, or frame the Site or any individual element
            within the Site, the name of the Site, any Croonos Lab trademark, logo, or other
            proprietary information, or the layout and design of any page or form contained on a
            page, without Croonos Lab's express written consent; (b) Access, tamper with, or use
            non-public areas of the Site, Croonos Lab's computer systems, or the technical delivery
            systems of Croonos Lab's providers; (c) Attempt to probe, scan, or test the
            vulnerability of any Croonos Lab system or network or breach any security or
            authentication measures; (d) Avoid, bypass, remove, deactivate, impair, decrypt, or
            otherwise circumvent any technological measure implemented by Croonos Lab or any of
            Croonos Lab's providers or any other third party (including another user) to protect the
            Site; (e) Attempt to access or search the Site or download content from the Site through
            the use of any engine, software, tool, agent, device, or mechanism (including spiders,
            robots, crawlers, data mining tools, or similar) other than the software and/or search
            agents provided by Croonos Lab or other generally available third-party web browsers;
            (f) Use the Site, or any portion thereof, for any commercial purpose or for the benefit
            of any third party or in any manner not permitted by these Terms; (g) Attempt to
            decipher, decompile, disassemble, or reverse engineer any software used to provide the
            Site; (h) Interfere with or attempt to interfere with the access of any user, host, or
            network, including, without limitation, sending a virus, overloading, flooding,
            spamming, or mail-bombing the Site; (i) Impersonate or misrepresent your affiliation
            with any person or entity; (j) Violate any applicable law or regulation; or (k)
            Encourage or enable any other individual to do any of the foregoing.
          </p>
          <p class="lh-sm text-white text-justify sofiaExtraLight" style="`${datos.horas}`">
            7. Croonos Lab is under no obligation to monitor access to or use of the Site or to
            review or edit any content. However, we have the right to do so in order to operate the
            Site, to ensure compliance with these Terms, and to comply with applicable law or other
            legal requirements. We reserve the right, but are not obligated, to remove or disable
            access to any content, at any time and without notice, including, but not limited to,
            if, in our sole discretion, we consider it to be objectionable or in violation of these
            Terms. We have the right to investigate violations of these Terms or conduct that
            affects the Site. We may also consult and cooperate with law enforcement authorities to
            prosecute users who violate the law.
          </p>
          <p class="lh-sm text-white text-justify sofiaExtraLight" style="`${datos.horas}`">
            8. Links to Third-Party Websites or Resources. The Site may allow you to access
            third-party websites or other resources. We provide access solely for your convenience,
            and we are not responsible for the content, products, or services available on those
            resources or links displayed on such websites. You acknowledge sole responsibility and
            assume all risks arising from your use of third-party resources.
          </p>
          <p class="lh-sm text-white text-justify sofiaExtraLight" style="`${datos.horas}`">
            9. Termination. We may suspend or terminate your access and use of the Site, at our sole
            discretion, at any time and without prior notice. Upon any termination, discontinuation,
            or cancellation of these Terms or the Site.
          </p>
          <p class="lh-sm text-white text-justify sofiaExtraLight" style="`${datos.horas}`">
            10. Warranty Disclaimer. THE SITE IS PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND.
            WITHOUT LIMITING THE FOREGOING, WE EXPLICITLY DISCLAIM ANY IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT, AND
            NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE.
            We do not warrant that the Site will meet your requirements or be available
            uninterrupted, secure, or error-free. We make no warranty regarding the quality,
            accuracy, timeliness, truthfulness, completeness, or reliability of any information or
            content on the Site. Any reliance you place on such information or content is strictly
            at your own risk.
          </p>
          <p class="lh-sm text-white text-justify sofiaExtraLight" style="`${datos.horas}`">
            11. Indemnification. You will indemnify and hold Croonos Lab and its directors,
            employees, partners, and agents harmless from and against any claim, dispute, demand,
            liability, damages, losses, and costs and expenses, including, without limitation,
            reasonable legal and accounting fees arising out of or in any way connected with (a)
            your access or use of the Site, or (b) your violation of these Terms.
          </p>
          <p class="lh-sm text-white text-justify sofiaExtraLight" style="`${datos.horas}`">
            12. Limitation of Liability. (a) TO THE MAXIMUM EXTENT PERMITTED BY LAW, NEITHER CROONOS
            LAB NOR ITS SERVICE PROVIDERS INVOLVED IN THE CREATION, PRODUCTION, OR DELIVERY OF THE
            SITE SHALL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES,
            OR DAMAGES FOR LOSS OF PROFITS, LOSS OF REVENUE, LOSS OF SAVINGS, LOSS OF BUSINESS
            OPPORTUNITY, LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE, OR SYSTEM
            FAILURE, OR THE COST OF SUBSTITUTE SERVICES OF ANY KIND ARISING OUT OF OR IN CONNECTION
            WITH THESE TERMS OR THE USE OR INABILITY TO USE THE WEBSITE, WHETHER BASED ON WARRANTY,
            CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY, OR ANY OTHER LEGAL THEORY, AND
            EVEN IF CROONOS LAB OR ITS SERVICE PROVIDERS HAVE BEEN ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGES, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS
            ESSENTIAL PURPOSE. (b) THE FOREGOING EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH
            ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE AGREEMENT BETWEEN CROONOS LAB AND
            YOU.
          </p>
          <p class="lh-sm text-white text-justify sofiaExtraLight" style="`${datos.horas}`">
            13. Governing Law and Choice of Forum. These Terms and any action related thereto shall
            be governed by and construed in accordance with Spanish law and courts.
          </p>
          <p class="lh-sm text-white text-justify sofiaExtraLight" style="`${datos.horas}`">
            14. Dispute Resolution. (a) Informal Resolution of Disputes. You and Croonos Lab must
            first attempt to resolve any dispute, claim, or controversy arising out of or relating
            to these Terms or the breach, termination, enforcement, interpretation, or validity
            thereof or the use of the Services (collectively, "Disputes") informally. Accordingly,
            neither you nor Croonos Lab may initiate a formal arbitration proceeding for at least
            sixty (60) days after one party notifies the other party in writing of a claim. As part
            of this informal resolution process, you must send your notices to us by first class
            mail to Croonos Lab, C/ Calderón de la Barca 3, 3C, 28013 Madrid, Spain. (b) Mandatory
            Arbitration of Disputes. Each of us agrees that any dispute shall be resolved solely
            through binding and individual arbitration and not in a class, representative, or
            consolidated action or proceeding. You and Croonos Lab agree that Spanish law governs
            the interpretation and enforcement of these Terms, and that both you and Croonos Lab
            waive the right to a trial or to participate in a class action. This arbitration
            provision shall survive the termination of these Terms. (c) Waiver of Class Action: YOU
            AND CROONOS LAB AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR
            INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
            REPRESENTATIVE ACTION. Additionally, if the parties' dispute is resolved through
            arbitration, the arbitrator may not consolidate other persons' claims with your claims
            and may not preside over any form of representative or class proceeding. If this
            specific provision is found to be unenforceable, then the entirety of this Dispute
            Resolution section shall be null and void. (d) Severability: Except for any provision in
            Section 14(c) of these Terms ("Waiver of Class Action"), if an arbitrator or competent
            court of jurisdiction determines that any part of these Terms is invalid or
            unenforceable, the remaining parts of these Terms shall continue to apply.
          </p>
          <p class="lh-sm text-white text-justify sofiaExtraLight" style="`${datos.horas}`">
            15. General Conditions: (a) Reservation of Rights: Croonos Lab and its licensors are the
            exclusive owners of all rights, titles, and interests in the Site, including all
            associated intellectual property rights. You acknowledge that the Site is protected by
            copyright, trademark, and other laws of Spain. You agree not to remove, alter, or
            conceal any copyright, trademark, service mark, or other proprietary notices
            incorporated or accompanying the Site. (b) Entire Agreement: These Terms constitute the
            entire and exclusive understanding and agreement between Croonos Lab and you regarding
            the Site, and these Terms supersede and replace any prior oral or written understandings
            or agreements between Croonos Lab and you regarding the Site. If any provision of these
            Terms is found to be invalid or unenforceable by a competent court of jurisdiction, such
            provision shall be enforced to the maximum extent permitted, and the remaining
            provisions of these Terms shall remain in full force and effect. Except as provided by
            applicable law in your jurisdiction, you may not assign or transfer these Terms, by
            operation of law or otherwise, without the prior written consent of Croonos Lab. Any
            attempt by you to assign or transfer these Terms without our consent or your legal right
            to do so will be null and void. Croonos Lab may freely assign or transfer these Terms
            without restrictions. Subject to the foregoing, these Terms shall bind and inure to the
            benefit of the parties, their successors, and authorized assigns. (c) Notices: All
            notices or other communications provided by Croonos Lab under these Terms will be posted
            on the Site. (d) Waiver of Rights: The failure of Croonos Lab to enforce any right or
            provision of these Terms shall not be considered a waiver of such right or provision.
            Waiver of any right or provision shall be effective only if made in writing and signed
            by a duly authorized representative of Croonos Lab. Unless expressly provided in these
            Terms, the exercise by either party of any of its remedies under these Terms shall be
            without prejudice to its other remedies under these Terms or otherwise.
          </p>
          <p class="lh-sm text-white text-justify sofiaExtraLight" style="`${datos.horas}`">
            16. Contact Information: If you have any questions about these Terms or the Site, please
            contact Croonos Lab at [support@croonos.io].
          </p>
        </div>
      </div>
    </div>
    <div :style="`height: ${datos.espaciador}`"></div>
    <div class="row justify-content-center">
      <div class="col-auto">
        <ul class="nav justify-content-center">
          <li class="nav-item ps-0">
            <a class="nav-link" href="https://www.marabiertonft.xyz">
              <p class="text-white text-opacity-50 futurism">PAST</p>
            </a>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ path: '#' }">
              <p class="text-white futurism" :style="`${datos.present}`">PRESENT</p>
            </router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="http://tempoland.xyz">
              <p class="text-white futurism" id="future" :style="`${datos.future}`">FUTURE</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div :style="`height: ${datos.espaciador}`"></div>
  </div>
  <modal-container />
  <Footer2Section />
</template>

<script setup>
/* eslint-disable */
import NavBar from "@/components/NavBar.vue";
import Footer2Section from "@/components/Footer2Section.vue";
import { onBeforeMount, onMounted, reactive } from "vue";
import { useRoute } from "vue-router";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";
import { SplitText } from "gsap/SplitText";

gsap.registerPlugin(ScrollTrigger, TextPlugin, SplitText);
const route = useRoute();

const datos = reactive({
  leng: "",
  segundos: "--bs-text-opacity: .12;",
  minutos: "--bs-text-opacity: .12;",
  horas: "--bs-text-opacity: .12;",
  present: "--bs-text-opacity: .75;",
  future: "--bs-text-opacity: .5;",
  tlSegundos: null,
  tlMinutos: null,
  tlHoras: null,
  espaciador: 0,
  timer: null,
});

const leng = async () => {
  if (route.params.leng === "zh") {
    datos.leng = "zh";
  }
};

const aniWhite = async (obj, s) => {
  const tl = gsap.timeline();
  tl.fromTo(obj, { opacity: 0, ease: "none" }, { opacity: 1, duration: s });
};

const aniOpacity = async (obj, s) => {
  const tl = gsap.timeline({ repeat: -1 });
  tl.fromTo(obj, { opacity: 0.5, ease: "none" }, { opacity: 1, duration: s });
  tl.fromTo(obj, { opacity: 1, ease: "none" }, { opacity: 0.5, duration: s });
  if (obj === "#segundos-text") {
    datos.tlSegundos = tl;
  }
  if (obj === "#minutos-text") {
    datos.tlMinutos = tl;
  }
  if (obj === "#horas-text") {
    datos.tlHoras = tl;
  }
};

const aniOpacityPause = async (obj) => {
  if (obj === "#segundos-text") {
    datos.tlSegundos.pause();
  }
  if (obj === "#minutos-text") {
    datos.tlMinutos.pause();
  }
  if (obj === "#horas-text") {
    datos.tlHoras.pause();
  }
};

const aniSplitText = async (ani, obj, eliminar = false) => {
  const id = document.getElementById(obj);
  let repeat = -1;
  if (eliminar) {
    repeat = 0;
  }
  // eslint-disable-next-line
  const mySplitText = new SplitText(id, { type: "words" });
  const splitTextTimeline = gsap.timeline({ repeat });
  gsap.set(id, { perspective: 400 });

  const kill = async () => {
    splitTextTimeline.clear().time(0);
    mySplitText.revert();
  };

  if (ani === "chars") {
    kill();
    mySplitText.split({ type: "chars, words" });
    splitTextTimeline.from(mySplitText.chars, {
      duration: 0.6,
      scale: 4,
      autoAlpha: 0,
      rotationX: -180,
      transformOrigin: "100% 50%",
      ease: "back",
      stagger: 0.02,
    });
  }
  if (ani === "words") {
    kill();
    mySplitText.split({ type: "words" });
    mySplitText.words.forEach((el, index) => {
      splitTextTimeline.from(el, { duration: 0.4, opacity: 0.9, force3D: true }, index * 0.01);
      splitTextTimeline.from(el, { duration: 0.7, scale: index % 2 === 0 ? 2 : 2 }, index * 0.01);
    });
  }
  if (ani === "lines") {
    kill();
    mySplitText.split({ type: "lines" });
    splitTextTimeline.from(mySplitText.lines, {
      duration: 0.5,
      opacity: 0,
      rotationX: -120,
      force3D: true,
      transformOrigin: "top center -150",
      stagger: 0.1,
    });
  }
  if (ani === "charsWordsLines") {
    kill();
    mySplitText.split({ type: "chars, words, lines" });
    splitTextTimeline
      .from(
        mySplitText.chars,
        {
          duration: 0.2,
          autoAlpha: 0,
          scale: 4,
          force3D: true,
          stagger: 0.01,
        },
        0.5
      )
      .to(
        mySplitText.words,
        {
          duration: 0.1,
          color: "#8FE402",
          scale: 0.9,
          stagger: 0.1,
        },
        "words"
      )
      .to(
        mySplitText.words,
        {
          duration: 0.2,
          color: "white",
          scale: 1,
          stagger: 0.1,
        },
        "words+=0.1"
      )
      .to(mySplitText.lines, {
        duration: 0.5,
        x: 100,
        autoAlpha: 0,
        stagger: 0.2,
      });
  }

  // revert the text back to its pre-split state
  if (ani === "revert") {
    mySplitText.revert();
  }
};

/* const detener = async (num) => {
  const pause = document.getElementById(`${num}-demo`);
  if (pause) {
    await pause.pause();
    await pause.load();
  }
}; */

const reproducir = async (num) => {
  const play = document.getElementById(`${num}-demo`);
  if (num === "segundos") {
    setTimeout(async () => {
      datos.segundos = "--bs-text-opacity: .75;";
      await aniOpacityPause("#segundos-text");
      await aniWhite("#segundos-text", 10);
    }, 3000);
  }
  if (num === "minutos") {
    datos.segundos = "--bs-text-opacity: .12;";
    datos.minutos = "--bs-text-opacity: .75;";
    datos.horas = "--bs-text-opacity: .12;";
    await aniOpacityPause("#minutos");
    await aniOpacity("#segundos", 3);
    await aniOpacity("#minutos", 0.7);
    await aniOpacity("#segundos-text", 3);
    await aniOpacityPause("#minutos-text");
    await aniWhite("#minutos-text", 10);
  }
  if (num === "horas") {
    datos.segundos = "--bs-text-opacity: .12;";
    datos.minutos = "--bs-text-opacity: .12;";
    datos.horas = "--bs-text-opacity: .75;";
    await aniOpacityPause("#minutos");
    await aniOpacity("#minutos-text", 3);
    await aniOpacity("#minutos", 3);
    await aniOpacityPause("#horas-text");
    await aniWhite("#horas-text", 10);
    play.addEventListener("timeupdate", async () => {
      if (play.currentTime >= 49) {
        await aniSplitText("opacity", "horas", true);
        datos.present = "--bs-text-opacity: .5;";
        datos.future = "--bs-text-opacity: .80;";
        await aniOpacity("#future", 0.7);
      }
    });
  }

  if (play) {
    if (num === "segundos") {
      setTimeout(async () => {
        await play.play();
      }, 5000);
    } else {
      await play.play();
    }
  }
};

const tScreen = async () => {
  datos.espaciador = "0.2vh";
  const height = document.documentElement.scrollHeight;
  if (height > 668 && height < 768) {
    datos.espaciador = "0.5vh";
  }
  if (height > 768 && height < 900) {
    datos.espaciador = "1.2vh";
  }
  if (height > 900 && height < 1000) {
    datos.espaciador = "2.2vh";
  }
  if (height > 1000 && height < 1100) {
    datos.espaciador = "3.5vh";
  }
  if (height > 1100 && height < 1200) {
    datos.espaciador = "4.5vh";
  }
  if (height > 1200 && height < 1300) {
    datos.espaciador = "6.5vh";
  }
  if (height > 1300 && height < 1400) {
    datos.espaciador = "7.5vh";
  }
};

const playScreen = async () => {
  if (datos.timer === null) {
    tScreen();
    datos.timer = setInterval(() => tScreen(), 1000);
  } else {
    clearInterval(datos.timer);
    datos.timer = null;
  }
};

onBeforeMount(async () => {});

onMounted(async () => {
  await leng();
  await playScreen();
  await aniOpacity("#segundos-text", 3);
  await aniOpacity("#minutos-text", 3);
  await aniOpacity("#horas-text", 3);
  await aniOpacity("#segundos", 0.7);
  await aniOpacity("#minutos", 3);
  await reproducir("segundos");
});
</script>

<style scoped lang="scss">
.nav {
  --bs-nav-link-padding-x: 0.2rem;
  --bs-nav-link-padding-y: 0.4rem;
}
</style>
